/*
 * @Author: duchengdong
 * @Date: 2021-10-27 10:28:45
 * @LastEditors: mawenli
 * @LastEditTime: 2022-07-07 11:48:18
 * @Description: 路由配置文件
 */
import AuthLayout, { BlankLayout } from 'layout/AuthLayout'
import { lazy } from 'react'

const RouteConfig = [
    {
        path: '/login',
        component: lazy(() => import('pages/Login')),
    },
    {
        path: '/',
        component: AuthLayout,
        children: [
            { path: '/', redirect: '/home' },
            {
                name: '工作台',
                path: '/home',
                component: BlankLayout,
                children: [
                    {
                        name: '仪表盘',
                        path: '/home/dashboard',
                        component: lazy(() => import('pages/HomeWork/DashEcharts')),
                    },
                    {
                        name: '我的待办',
                        path: '/home/worktodo',
                        component: lazy(() => import('pages/HomeWork/WorkTodo')),
                    },
                    {
                        name: '我的已办',
                        path: '/home/workalready',
                        component: lazy(() => import('pages/HomeWork/WorkAlready')),
                    },
                ],
            },
            {
                name: '直播',
                path: '/live',
                component: BlankLayout,
                children: [
                    {
                        path: '/live/livemanage/broadcastlist/livedata/:id/:liveName/:liveStatus/:startTime/:endTime?',
                        name: '直播数据',
                        component: lazy(() => import('pages/live/LiveBroadcastList/liveData')),
                    },
                    {
                        path: '/live/livemanage/broadcastlist/add',
                        name: '创建直播',
                        component: lazy(() => import('pages/live/LiveBroadcastList/AddAngEdit')),
                    },
                    {
                        path: '/live/livemanage/broadcastlist/edit/:id?',
                        name: '编辑直播',
                        component: lazy(() => import('pages/live/LiveBroadcastList/AddAngEdit')),
                    },
                    {
                        path: '/live/livemanage/broadcastlist/setting/:id?',
                        name: '直播设置',
                        component: lazy(() => import('pages/live/LiveBroadcastList/liveSetting')),
                    },
                    {
                        name: '直播列表',
                        path: '/live/livemanage/broadcastlist',
                        component: lazy(() => import('pages/live/LiveBroadcastList')),
                    },
                    {
                        name: '消息列表',
                        path: '/live/livemanage/messagelist',
                        component: lazy(() => import('pages/live/MessageList')),
                    },
                    {
                        name: '敏感词汇',
                        path: '/live/livemanage/sensitivewords',
                        component: lazy(() => import('pages/live/SensitiveWords')),
                    },
                    {
                        name: '讲师管理',
                        path: '/live/lecturermamage',
                        component: lazy(() => import('pages/live/LecturerMamage')),
                    },
                    {
                        name: '授权管理',
                        path: '/live/authmanage',
                        component: lazy(() => import('pages/AuthorizationManage')),
                    },
                ],
            },
            {
                name: '运营',
                path: '/operation',
                component: BlankLayout,
                children: [
                    {
                        name: '患者详情',
                        path: `/operation/patient/patientmanage/detail/:id/:userId`,
                        component: lazy(() => import('pages/PatientService/PatientManage/PatientDetail')),
                    },
                    {
                        name: '患者管理',
                        path: '/operation/patient/patientmanage',
                        component: lazy(() => import('pages/PatientService/PatientManage')),
                    },
                    {
                        name: '用户管理详情',
                        path: `/operation/patient/usermanage/detail/:id?`,
                        component: lazy(() => import('pages/PatientService/UserManage/UserDetail')),
                    },
                    {
                        name: '用户管理',
                        path: '/operation/patient/usermanage',
                        component: lazy(() => import('pages/PatientService/UserManage')),
                    },
                    {
                        name: '服务单详情',
                        path: `/operation/patient/serviceorder/detail/:id?`,
                        component: lazy(() => import('pages/PatientService/ServiceOrder/ServiceDetail')),
                    },
                    {
                        name: '服务单管理',
                        path: '/operation/patient/serviceorder',
                        component: lazy(() => import('pages/PatientService/ServiceOrder')),
                    },
                    {
                        name: '医疗机构/编辑/新增',
                        path: '/operation/medical/medicalmanage/edit/:type/:id',
                        component: lazy(() => import('pages/MedicalService/MedicalManage/MedicalEdit')),
                    },
                    {
                        name: '科室列表',
                        path: '/operation/medical/medicalmanage/departmentlist/:tname/:id',
                        component: lazy(() => import('pages/MedicalService/MedicalManage/DepartmentList')),
                    },
                    {
                        name: '医疗机构',
                        path: '/operation/medical/medicalmanage',
                        component: lazy(() => import('pages/MedicalService/MedicalManage')),
                    },
                    {
                        name: '医生管理详情/编辑',
                        path: `/operation/medical/doctormanage/:type/:id?`,
                        component: lazy(() => import('pages/MedicalService/DoctorManage/DoctorDetalis')),
                    },
                    {
                        name: '医生管理',
                        path: '/operation/medical/doctormanage',
                        component: lazy(() => import('pages/MedicalService/DoctorManage')),
                    },
                    {
                        name: '角色管理',
                        path: '/operation/set/rolemanage',
                        component: lazy(() => import('pages/ProjectSettings/RoleManage')),
                    },
                    {
                        name: '成员管理',
                        path: '/operation/set/membermanage',
                        component: lazy(() => import('pages/ProjectSettings/MemberManage')),
                    },
                    {
                        path: '/operation/content/articlecontent/edit',
                        name: '文章管理编辑',
                        component: lazy(() => import('pages/ContentManage/ArticleContent/AddArticle')),
                    },
                    {
                        name: '文章管理',
                        path: '/operation/content/articlecontent',
                        component: lazy(() => import('pages/ContentManage/ArticleContent')),
                    },
                    {
                        path: '/operation/content/videocontent/edit',
                        name: '课程内容编辑',
                        component: lazy(() => import('pages/ContentManage/VideoContent/AddCourse')),
                    },
                    {
                        name: '课程内容',
                        path: '/operation/content/videocontent',
                        component: lazy(() => import('pages/ContentManage/VideoContent')),
                    },
                    {
                        name: '课程内容',
                        path: '/operation/content/videocontent',
                        component: lazy(() => import('pages/ContentManage/VideoContent')),
                    },
                    {
                        name: '角色管理',
                        path: '/operation/set/rolemanage',
                        component: lazy(() => import('pages/ProjectSettings/RoleManage')),
                    },
                    {
                        name: '成员管理',
                        path: '/operation/set/membermanage',
                        component: lazy(() => import('pages/ProjectSettings/MemberManage')),
                    },
                ],
            },
        ],
    },
]

export const initRouteConfig = [
    {
        path: '/login',
        component: lazy(() => import('pages/Login')),
    },
]

export default RouteConfig
