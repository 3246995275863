/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-26 15:41:12
 * @LastEditors: mawenli
 * @LastEditTime: 2022-05-10 14:27:15
 * @Description: 全局公共的api
 */
import http from 'utils/request'
import { message } from 'antd'
import { storeDispatch, storeActions } from 'store'
import { setStorage } from 'utils/localStorage'
import { NavRoutesFunc } from 'utils/pagesFunc'
import { handleHeaderLink } from 'layout/AuthLayout'
const { saveUserInfo, saveProjectList, saveCurrentProject, saveNavMenuList } = storeActions['globle']
//企业微信扫码登录
export const queryLogin = (code: string, appid: string, state: string) => {
    return http({
        url: `/auth/scan?code=${code}&corpId=${appid}&state=${state}`,
        method: 'get',
    }).then((res) => {
        if (res?.code === 100) {
            const data = res?.data
            //1.在store中存储用户信息
            storeDispatch(saveUserInfo(data?.userinfo || {}))
            //2.在localStorage中存储token
            setStorage('token', data?.access_token || '', true)
        } else {
            message.error(res?.msg || '登录请求失败')
        }
        return res
    })
}
/**
 * 获取项目列表
 * @param data {}
 * @param save login页面调用时不需要存储：false
 * @returns
 */
export const queryProjectList = (data: {}, save: boolean = true) => {
    return http({
        // url: `/system/project/list`,
        url: `/hospital/project/list`,
        method: 'post',
        data,
    }).then((res) => {
        if (res.code === 100) {
            if (save) {
                //在store中存储项目列表
                const data: any = res?.data || []
                storeDispatch(saveProjectList(data))
                storeDispatch(saveCurrentProject(data[0]))
            }
        } else {
            message.error(res?.msg || '项目数据请求失败')
        }
        return res
    })
}
/**
 * 根据项目ID 获取菜单数据
 * @param projectId 项目ID
 * @param save  login页面调用时不需要存储：false
 * @returns
 */
export const queryMenuList = (projectId: string, save: boolean = true) => {
    return http({
        // url: `/system/menu/getMenuTree?projectId=${projectId}`,
        url: `/hospital/menu/getMenuTree?projectId=${projectId}`,
        method: 'get',
    }).then((res) => {
        let homePath: string = process.env.REACT_APP_loginPath || '/login',
            routes: any[] = []

        if (res.code === 100) {
            const list: any = res?.data || []
            if (save) {
                //1.在store中存储菜单信息
                storeDispatch(saveNavMenuList(list))
                //2.Route处理
                routes = NavRoutesFunc(list)
            }
            //3.登录成功--获取跳转的页面
            homePath = handleHeaderLink(list[0] || [])
        } else {
            if (save) {
                routes = NavRoutesFunc([])
            }
            message.error(res?.msg || '菜单数据请求失败')
        }

        return { homePath, routes, code: res.code }
    })
}

//获取当前用户信息
export const getUserInfo = () => {
    return http({
        url: '/auth/parseToken',
        method: 'get',
    }).then((res) => {
        if (res.code === 100) {
            //1.在store中存储用户信息
            storeDispatch(saveUserInfo(res?.data || {}))
        } else {
            message.error(res?.msg || '用户信息获取失败')
        }
        return res
    })
}
//刷新token
export const onRefreshToken = () => {
    return http({
        url: '/auth/refresh',
        method: 'get',
    }).then((res) => {
        //1.在store中存储用户信息
        storeDispatch(saveUserInfo(res?.data?.userinfo || {}))
        //2.在localStorage中存储token
        setStorage('token', res?.data?.access_token || '', true)
        return res
    })
}
/**
 * 企业部门列表接口
 * @param {所属企业ID} corpId
 * @param {部门ID,默认为1根部门 } departmentId
 */
export const getDepartmentsList = (corpId: string) => {
    return http({
        // url: `/system/enterorg/menulist`,
        url: `/hospital/enterorg/menulist`,
        method: 'get',
        params: {
            corpId,
        },
    })
        .then((res: any) => {
            if (res.code === 100) {
                const data = res?.data instanceof Array ? res?.data : [res?.data]
                let treeData = funMap(data)
                return treeData
            } else {
                throw res
            }
        })
        .catch((err) => {
            console.log(err, 'err---')
            message.error(err?.msg)
            return []
        })
}

const funMap = (data: any) => {
    return data?.map((item: any) => ({
        // ...item,
        title: item.orgName,
        key: item.qyOrgId + '',
        children: funMap(item.children),
    }))
}

//投放平台字典+投放平台下的模块 /（id=-1表示投放平台；其他值表示所属模块）
export const getBizDicByParentId = (id: any) =>
    http({
        // url: `/system/bizDic/parentId/${id}`,
        url: `/hospital/bizDic/parentId/${id}`,
        method: 'get',
    })

//投放平台字典+投放平台下的模块 一个接口返回所有 用于直播列表table查询及显示
export const getAllBizDicByParentId = (id: any) =>
    http({
        // url: `/system/bizDic/${id}`,
        url: `/hospital/bizDic/${id}`,
        method: 'get',
    })

//直播上传文件
export const promiseFile = (data: any) => {
    return http({
        url: '/platform/sys/upload',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
    })
}

//获取科室目录字典信息
export const getdictItem = (dictId: string, parentCode?: string) => {
    return http({
        url: `/platform/backstage/cfg/dictItem/${dictId}${parentCode ? '/' + parentCode : ''}`,
        method: 'get',
    })
}
