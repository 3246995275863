/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-26 17:55:26
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-10-26 17:55:27
 * @Description:
 */
import { Spin } from 'antd'
const LoadingHtmp = () => {
    return (
        <div className="position-center">
            <Spin />
        </div>
    )
}

export default LoadingHtmp
