/*
 * @Author: zhicheng.yao
 * @Date: 2021-10-19 18:00:39
 * @Last Modified by:   zhicheng.yao
 * @Last Modified time: 2021-10-19 18:00:39
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userInfoType, ProjectItem, MenuItem } from 'types/store.type'
//初始化数据
//用户信息
const userInfo: userInfoType = {}
//项目列表
const projectList: ProjectItem[] = []
//当前项目
const currentProject: ProjectItem = {}
//菜单数据
const navMenuList: MenuItem[] = []

type StateType = {
    userInfo: userInfoType
    projectList: ProjectItem[]
    navMenuList: MenuItem[]
    currentProject: ProjectItem
}
const initialState: StateType = {
    userInfo,
    projectList,
    navMenuList,
    currentProject,
}
export const globleSlice = createSlice({
    name: 'globle',
    initialState,
    reducers: {
        saveUserInfo: (state, action: PayloadAction<userInfoType>) => {
            state.userInfo = action.payload
        },
        saveProjectList: (state, action: PayloadAction<ProjectItem[]>) => {
            state.projectList = action.payload
        },
        saveNavMenuList: (state, action: PayloadAction<MenuItem[]>) => {
            state.navMenuList = action.payload
        },
        saveCurrentProject: (state, action: PayloadAction<ProjectItem>) => {
            state.currentProject = action.payload
        },
    },
})
export default globleSlice.reducer
