/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-26 15:02:07
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-19 15:05:49
 * @Description:退出登陆
 */
import { setStorage } from 'utils/localStorage'
const login = process.env.REACT_APP_loginPath || '/login'
export const Logout = () => {
    setStorage('token', {})
    window.location.href = login
}
