/*
 * @Author: zhicheng.yao
 * @Date: 2021-11-02 10:04:17
 * @Last Modified by: zhicheng.yao
 * @Last Modified time: 2021-11-20 16:35:47
 * 接口域名地址：http://test.gateway.rnhos.com/platform
 */
import http from 'utils/request'
import { PageInfo, ResObj, TypeValue } from 'types/config.type.d'

//----------讲师列表----------------
//添加讲师
export const createLecturer = (data: ResObj) => {
    return http({
        url: '/platform/operation/lecturer/save',
        method: 'post',
        data,
    })
}

//获取讲师列表
export const getLecturerList = (data: ResObj) => {
    return http({
        url: '/platform/operation/lecturer/list',
        method: 'post',
        data,
    })
}

//讲师详情
export const lecturerDetail = (id: TypeValue) => {
    return http({
        url: '/platform/lecturer/detail/' + id,
        method: 'post',
    })
}

//---------------直播列表------------------
//创建直播
export const createLive = (data: ResObj) => {
    return http({
        url: '/platform/operation/live_info/list/live',
        method: 'post',
        data,
    })
}

//编辑直播
export const editLive = (data: ResObj) => {
    return http({
        url: '/platform/operation/live_info/list/live',
        method: 'patch',
        data,
        // baseURL: 'http://192.168.20.2:8080',
    })
}
//获取直播列表
export const getLiveList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/platform/operation/live_info/list',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
        // baseURL: 'http://192.168.20.2:8080',
    }).then((res) => {
        return res
    })
}

export const getLiveListRelevant = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/platform/live_info/list/relevant_live',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
        // baseURL: 'http://192.168.20.2:8080',
    }).then((res) => {
        return res
    })
}
//获取直播详情
export const getLiveDetail = (id: TypeValue) => {
    return http({
        url: '/platform/live_info/list/detail?liveId=' + id,
        method: 'get',
    })
}

//发布
export const liveRelease = (params: any) => {
    return http({
        url: '/platform/operation/live_info/list/release',
        method: 'get',
        params,
    })
}

//获取直播账户信息
export const liveAccount = (emait: any) => {
    return http({
        url: '/platform/live_info/list/live_account?email=' + emait,
        method: 'get',
    })
}

//手动推流
export const liveStstus = (id) => {
    return http({
        url: '/platform/live/status?liveId=' + id,
        method: 'patch',
    })
}

//---------------直播管理-消息列表------------------
//获取消息列表
export const getMessageList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/platform/operation/list',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
    })
}

//讨论命令操作,1禁言 2 取消禁言 3 屏蔽 4 取消屏蔽
export const messageOpera = (id: TypeValue, type: TypeValue) => {
    return http({
        url: `/platform/operation/discuss/operation/${id}/${type}`,
        method: 'get',
    })
}

//--------------------敏感词管理------------------
//获取敏感词列表
export const getSensitiveWords = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/platform/operation/sensitive/list',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
    })
}

//移除敏感词
export const removeSensitive = (data: ResObj) => {
    return http({
        url: '/platform/operation/sensitive/del',
        method: 'post',
        data,
    })
}

//添加敏感词
export const addSensitive = (data: ResObj) => {
    return http({
        url: '/platform/operation/sensitive/add',
        method: 'post',
        data,
    })
}

//授权服务号 下拉框
export const getAuthOption = (data: ResObj) => {
    return http({
        url: '/platform/operation/authorizers:drop_list',
        method: 'post',
        data,
    })
}

//切换推拉流并推流
export const changeStream = (data: ResObj) => {
    return http({
        url: `/platform/operation/live/stream:change?liveId=${data.liveId}&streamPlatformCode=${data.streamPlatformCode}`,
        method: 'post',
    })
}

export const getDictPlatForm = (id: any) =>
    http({
        url: `/platform/system/bizDic/parentId/${id}`,
        method: 'get',
    })

//直播数据
export const getLiveData = (data: ResObj) => {
    return http({
        url: `/platform/operation/live_info/lives/${data.id}/details?currentPage=${data.currentPage}&pageSize=${data.pageSize}&type=${data.type}`,
        method: 'get',
    })
}

//导出直播数据
export const exportLiveData = (data: ResObj) => {
    return http({
        url: `/platform/operation/lives/${data.id}:data_export_excel?type=${data.type}`,
        // url:`/platform/operation/lives/${data.id}:data_export?type=${data.type}`,
        responseType: 'blob',
    })
}

//主持人发言
export const sendMessage = (data: ResObj) => {
    return http({
        url: '/platform/operation/group_messages',
        method: 'post',
        data,
    })
}

//直播设置 编辑
export const saveLiveSetting = (data: ResObj) => {
    return http({
        url: '/platform/operation/live_info/editSenior',
        method: 'post',
        data,
    })
}

//直播数据-讨论消息列表接口
export const getMessageByIdData = (data: ResObj) => {
    return http({
        url: '/platform/operation/listByLiveId',
        method: 'post',
        data,
    })
}

//讨论消息导出
export const exportMessageData = (data: ResObj) => {
    return http({
        url: '/platform/operation/list/export',
        method: 'post',
        responseType: 'blob',
        data,
    })
}

//直播设置 评论列表
export const getLiveingCommentListApi = (liveId: number) => {
    return http({
        url: '/platform/operation/fast_comments',
        method: 'get',
        params: { liveId },
    })
}

//直播设置 保存评论列表
export const saveLiveCommentListApi = (data: ResObj) => {
    return http({
        url: '/platform/operation/fast_comments',
        method: 'post',
        data,
    })
}
