/*
 * @Author: duchengdong
 * @Date: 2021-10-27 10:42:21
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-17 19:25:37
 * @Description:
 */
import { Redirect, Route, Switch } from 'react-router-dom'

export interface IRouteViewProps {
    path?: string
    redirect?: string
    component?: any
    name?: string
    children?: IRouteViewProps[]
}

const RouteView = (props: IRouteViewProps) => {
    return (
        <Switch>
            {props.children &&
                props.children.map((item, index) => {
                    if (item.redirect) {
                        return <Redirect key={index} from={item.path} to={item.redirect} />
                    }
                    return (
                        <Route
                            key={index}
                            path={item.path}
                            render={(props) => {
                                return item.component && <item.component children={item.children} {...props} name={item.name} />
                            }}
                        ></Route>
                    )
                })}
        </Switch>
    )
}

export default RouteView
