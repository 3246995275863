/*
 * @Author: duchengdong
 * @Date: 2021-11-05 13:44:48
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-11 13:34:46
 * @Description:
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from './index'
import { PageInfo } from 'types/config.type'

type InitialStateType = {
    tableLoading: boolean
    searchData: {}
    pageInfo: PageInfo
    dataList: any[]
}

const pageInfo = {
    currentPage: 1,
    pageSize: 20,
    totalRecords: 1,
}
const searchData = {}
const dataList = []

const initialState: InitialStateType = {
    tableLoading: true,
    searchData,
    pageInfo,
    dataList,
}

export function createListStore(NAME: string, REQUEST_API: any) {
    const getList = createAsyncThunk(`${NAME}/getList`, async (options: { pageInfo: any; searchData: any }) => {
        const response = await REQUEST_API(options.pageInfo, options.searchData)
        return {
            data: response.data ?? [],
            pageInfo: response.pageInfo,
        }
    })

    const listSlice = createSlice({
        name: NAME,
        initialState,
        reducers: {
            setTableLoading: (state, action) => {
                state.tableLoading = action.payload
            },
            setDataList: (state, action) => {
                state.dataList = action.payload.data
                state.pageInfo = action.payload.pageInfo
            },
            setSearchData: (state, action) => {
                state.searchData = action.payload
            },
        },
        extraReducers(builder) {
            builder
                .addCase(getList.pending, (state, action) => {
                    state.tableLoading = true
                })
                .addCase(getList.fulfilled, (state, action) => {
                    state.tableLoading = false
                    // Add any fetched posts to the array
                    state.dataList = action.payload.data
                    state.pageInfo = {
                        ...state.pageInfo,
                        ...action.payload.pageInfo,
                    }
                })
                .addCase(getList.rejected, (state, action) => {
                    state.tableLoading = false
                })
        },
    })

    const actions = listSlice.actions
    const selectListState = (state: RootState) => state[NAME]
    const listReducer = listSlice.reducer

    return {
        listSlice,
        actions,
        selectListState,
        listReducer,
        getList,
    }
}
