/*
 * @Author: duchengdong
 * @Date: 2021-11-05 14:36:40
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-04-15 15:01:00
 * @Description: 患者服务
 */
import http from 'utils/request'
import Mock, { Random } from 'mockjs'
import { PageInfo, ResObj } from 'types/config.type'

//1患者管理列表
export const getPatientList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/hospital/patient/page',
        method: 'post',
        data: { ...pageInfo, ...data },
    })
}

//2患者管理详情-健康档案 /patient/detail/{id}
export const patientDetail = (id: string) => {
    return http({
        url: `/hospital/patient/detail/${id}`,
        method: 'get',
    })
}

//3患者管理详情-量表记录-血糖记录查询接口：   /patientIndex/trend
export const bloodEchartData = (data: ResObj) => {
    return http({
        url: `/hospital/patientIndex/trend`,
        method: 'post',
        data,
    })
}
//4患者管理详情-量表记录-bmi记录查询接口：   /patientBmi/trend
export const bmiEchartData = (data: ResObj) => {
    return http({
        url: `/hospital/patientBmi/trend`,
        method: 'post',
        data,
    })
}

//5用户管理列表 /patientUser/page
export const getPatientUserList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/hospital/patientUser/page',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
    })
}
//6用户管理详情 /patientUser/detail/{userId}
export const patientUserDetail = (id: string) => {
    return http({
        url: `/hospital/patientUser/detail/${id}`,
        method: 'get',
    })
}
//7 用户管理---用户等级
export const getUserLevel = () => {
    return http({
        url: `/hospital/cfg/level/standard`,
        method: 'get',
    })
}
//8 用户管理--是否启用（用户被禁用、默认开启）
export const changeUserStatus = (data: ResObj) => {
    return http({
        url: `/hospital/patientUser?id=${data.id}&status=${data.status}`,
        method: 'put',
    })
}

//7服务单管理列表(问诊管理)
export const getServiceOrderList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/hospital/inquiry/page',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
    })
}

//8服务单管理详情(问诊详情)
export const serviceOrderDetail = (id: string) => {
    return http({
        url: `/hospital/inquiry/detail/${id}`,
        method: 'get',
    })
}
//9获取聊天历史（用户详情）!!!!
export const getChartHistory = (data) => {
    return http({
        url: '/hospital/inquiry/page',
        method: 'post',
        data,
    }).then((res) => {
        return Mock.mock({
            code: 100,
            msg: 'string',
            requestId: 'string',
            pageInfo: {
                currentPage: data.pageInfo.currentPage,
                pageSize: data.pageInfo.pageSize,
                totalPage: 10,
                totalRecords: 184,
            },
            'data|1-10': [
                {
                    'chartType|1': ['text', 'msg'],
                    name: Random.cword(2, 3),
                    createTime: '2021-11-10 05:55:47',
                    avatar: 'https://diabetes-rn.oss-cn-shanghai.aliyuncs.com/diabetes-images/default-head.png',
                    src: 'https://diabetes-rn.oss-cn-shanghai.aliyuncs.com/diabetes-images/default-head.png',
                    msg: Random.cparagraph(),
                    'fromType|1': ['0', '1', '2'],
                    'id|1-999': 20,
                },
            ],
        })
    })
}
