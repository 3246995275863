/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-27 19:16:59
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-18 11:53:58
 * @Description:
 */
import { useAppSelector, useAppDispatch } from 'hooks'
import { storeState, storeStateFn } from 'store'

const useStoreHooks = (name: string) => {
    //state
    const state = useAppSelector(storeState(name))
    //setstate
    const dispatch = useAppDispatch()
    const actions = storeStateFn(name)
    return [state, actions, dispatch]
}

export default useStoreHooks
