/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-06 16:46:56
 * @LastEditors: Conner
 * @LastEditTime: 2023-03-29 16:56:53
 * @Description:医疗服务
 */

import http from 'utils/request'
import Mock from 'mockjs'
import { PageInfo, ResObj } from 'types/config.type.d'

//1医疗机构列表
export const getMedicalList = (pageInfo: PageInfo, searchData: ResObj) => {
    return http({
        url: '/platform/backstage/hospital/page',
        method: 'post',
        data: {
            ...pageInfo,
            ...searchData,
        },
    })
}

//2医疗机构详情
export const medicalDetails = (id: string) => {
    return http({
        url: `/hospital/hospital/detail/${id}`,
        method: 'get',
    })
}

//4新增机构/编辑机构
export const saveMedical = (data) => {
    return http({
        url: `/hospital/hospital`,
        method: 'post',
        data,
    })
}
//删除机构
export const deleteMedical = (id: string) => {
    return http({
        url: `/hospital/hospital/${id}`,
        method: 'delete',
    })
}

//3科室列表
export const getDepartmentList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/platform/backstage/department/list',
        method: 'post',
        data: {
            ...pageInfo,
            ...data,
        },
    })
}
//4新增+编辑 一级科室 二级科室
export const saveDepartment = (data) => {
    return http({
        url: `/hospital/department`,
        method: 'post',
        data,
    })
}

//5删除科室
export const deleteDepartment = (id: string) => {
    return http({
        url: `/hospital/department/${id}`,
        method: 'delete',
    })
}

//6医生管理列表
export const getDoctorList = (pageInfo: PageInfo, searchData: ResObj) => {
    return http({
        url: '/platform/backstage/doctor/page',
        method: 'post',
        data: {
            ...pageInfo,
            ...searchData,
        },
    })
}

//7修改医生状态、删除医生 status:1正常 2禁用 3删除
export const isStartUsingDoctor = (data: ResObj) => {
    return http({
        url: `/hospital/doctor?id=${data.id}&status=${data.status}`,
        method: 'put',
    })
}

//8.1添加医生 !!!!
export const addDoctor = (data: ResObj) => {
    return http({
        url: `/hospital/doctor/registry/doctor`,
        method: 'post',
        data,
    })
}
//8.2编辑医生
export const editDoctor = (data: ResObj) => {
    return http({
        url: `/hospital/doctor/basic`,
        method: 'post',
        data,
    })
}

//9医生详情-基本信息
export const doctorDetail = (id: any) => {
    return http({
        url: `/hospital/doctor/detail/${id}`,
        method: 'get',
    })
}

//11医生详情-认证信息
export const doctorAudit = (id) => {
    return http({
        url: `/hospital/doctor/verification/${id}`,
        method: 'get',
    })
}

//12医生详情服务列表
export const doctorServiceList = (data) => {
    return http({
        url: `/hospital/doctor/inquiry/setting/${data.id}`,
        method: 'get',
    })
}

//13 医生认证
export const auditDoctor = (data) => {
    return http({
        url: `/hospital/doctor/verify`,
        method: 'post',
        data,
    })
}

//14服务列表设置确定接口 ！！！！
export const editDoctorService = (data) => {
    return http({
        url: `/hospital/editDoctorService/page?`,
        method: 'post',
        data,
    }).then((res) => {
        return Mock.mock({
            code: 100,
            msg: null,
            requestId: null,
        })
    })
}

//15服务列表上下架接口
export const isOpenService = (data) => {
    return http({
        url: `/hospital/doctor/inquiry/setting?id=${data.id}&status=${data.status}`,
        method: 'put',
    })
}
