/*
 * @Author: mawenli
 * @Date: 2021-11-09 17:35:09
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-04-15 15:51:27
 * @Description:直播---授权管理
 */

import http from 'utils/request'
import { PageInfo, ResObj } from 'types/config.type'

//----------直播--授权管理----------------
export const getAuthList = (pageInfo: PageInfo, data: ResObj) => {
    return http({
        url: '/platform/operation/authorizers:list',
        method: 'post',
        data: { ...pageInfo, ...data },
    })
}

export const getCodePage = () => {
    return http({
        url: '/platform/third_party/pages/code_page',
        method: 'get',
    })
}

export const sendCode = (data: ResObj) => {
    return http({
        url: '/platform/third_party/authorizers:get_info',
        method: 'post',
        data: { ...data },
    })
}
