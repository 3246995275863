/*
 * @Author: duchengdong
 * @Date: 2021-10-12 16:16:44
 * @LastEditors: duchengdong
 * @LastEditTime: 2021-10-14 10:10:27
 * @Description:
 */
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {RootState, AppDispatch} from '../store'

//useDispatch hook with types.
// 获取dispatch方法，更新state
// 例：1. const dispatch = useAppDispatch();
// 2.  dispatch(addNewBook({ author, title, id: uuid() }));

export const useAppDispatch = () => useDispatch<AppDispatch>()
//useSelector hook with types
// 获取state里的数据,例：const bookList = useAppSelector((˘/。/state.book.bookList);
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
