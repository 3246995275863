/*
 * @Author: duchengdong
 * @Date: 2021-10-12 15:44:10
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-18 11:53:38
 * @Description:
 */
import { configureStore } from '@reduxjs/toolkit'
import { globleSlice } from './globleSlice' //引入公共的配置
import { createListStore } from './listStoreSlice' //创建createSlice
import { listStoreConfig } from './storeConfig' // 配置列表页store

const listStore: any[] = listStoreConfig.map((v) => {
    let storeRes = createListStore(v.name, v.request_api)
    return {
        ...v,
        ...storeRes,
    }
})

let storeConfig = {
    reducer: {
        globle: globleSlice.reducer,
    },
}

let storeActionsConfig = {
    globle: globleSlice.actions,
}

listStore.forEach((v) => {
    storeConfig.reducer[v.name] = v.listReducer
    storeActionsConfig[v.name] = v.actions
})

//reducer
export const store = configureStore(storeConfig)
//actions
export const storeActions = storeActionsConfig
//用于方法中的redux数据存储
export const storeDispatch = store.dispatch

export type RootState = ReturnType<typeof store.getState> // A global type to access reducers types
export type AppDispatch = typeof store.dispatch // Type to access dispatch

//返回state值
export const storeState = (name: string) => {
    return (state: RootState) => state[name]
}
//返回actions方法
export const storeStateFn = (name: string) => {
    return storeActions[name]
}
// 返回指定list列表 store
export const getStoreByName = (name: string) => {
    return listStore.find((v) => v.name === name)
}
