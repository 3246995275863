/*
 * @Author: yangmiaomiao
 * @Date: 2021-11-11 11:17:09
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2022-04-15 15:10:31
 * @Description:
 */
import http from 'utils/request'
import { PageInfo, ResObj } from 'types/config.type.d'
//获取公司成员列表
export const getCorpMemberList = (pageInfo: PageInfo, searchData: ResObj) => {
    return http({
        // url: '/system/user/departList',
        url: '/hospital/user/departList',
        method: 'post',
        data: {
            ...pageInfo,
            ...searchData,
        },
    })
}
