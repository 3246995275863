/*
 * @Author: yangmiaomiao
 * @Date: 2021-10-30 14:17:49
 * @LastEditors: yangmiaomiao
 * @LastEditTime: 2021-11-17 17:16:46
 * @Description:
 */

import { FC, useState, useEffect } from 'react'
import useStoreHooks from 'hooks/useStoreHooks'
import { ProjectItem } from 'types/store.type'
import './index.scss'

interface PropsType {}
const ProjectTabs: FC<PropsType> = () => {
    //项目列表
    const [state, actions, dispatch] = useStoreHooks('globle')

    const { projectList, currentProject } = state
    const { saveCurrentProject } = actions
    //当前项目
    const [current, setCurrent] = useState<ProjectItem>(currentProject)
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        setCurrent(currentProject)
        document.addEventListener('click', hideShow)
        return () => {
            document.removeEventListener('click', hideShow)
        }
    }, [currentProject])

    //隐藏弹框
    function hideShow() {
        setShow(false)
    }

    //切换项目
    function changeProject(item: ProjectItem) {
        //存储当前的项目
        dispatch(saveCurrentProject(item))
        //App中监听currentProject获取新的菜单列表
    }

    return (
        <div className="layout-tabsbox">
            {projectList?.length > 0 && (
                <div
                    className="tabs tabs-item"
                    onClick={(e) => {
                        e.nativeEvent.stopImmediatePropagation()
                        setShow(!show)
                    }}
                >
                    <TabsItem item={current} />
                    {projectList.length > 1 && <PointBox status={show} />}
                </div>
            )}

            {show && (
                <div className="tabs tabs-list">
                    {projectList?.length > 0 &&
                        projectList.map((item: ProjectItem) => (
                            <div className={`tabs-list-item ${current.id === item.id ? 'active' : ''}`} key={item.id} onClick={() => changeProject(item)}>
                                <TabsItem item={item} />
                            </div>
                        ))}
                    <div className="tabs-list-des">切换项目</div>
                </div>
            )}
        </div>
    )
}

export default ProjectTabs

const TabsItem = ({ item }) => (
    <>
        <img className="tabs-img" src={`${process.env.PUBLIC_URL + '/images/' + item.icon + '.png'}`} alt="" />
        {/* <img className="tabs-img" src={`${process.env.PUBLIC_URL + '/images/icon-project-yiliao.png'}`} alt="" /> */}
        <span className="tabs-name m-text-hide">{item.projectName}</span>
    </>
)

export const PointBox = ({ status }) => (
    <span className={`point-box ${status ? 'point-active' : ''}`}>
        <i className="iconfont icon-gengduo icon-20 icon-point"></i>
    </span>
)
